import { get } from "lodash";
import React from "react";

import {
  FlashMessageContextInjectedProps,
  GTMService,
  navigateTo,
  withFlashMessage,
} from "@interactive-investor/onestack-web-shared";

import Auth0WrapperFree from "./Auth0Wrapper/free";
import {
  AuthContextInjectedProps,
  AuthContextInterface,
} from "./interfaces/context";
import { AuthContextProps } from "./interfaces/props";
import { AuthScope } from "./interfaces/scopes";
import { AuthContextState } from "./interfaces/state";

/**
 * Non-exported Context
 */
const Context = React.createContext<AuthContextInterface>(
  (undefined as any) as AuthContextInterface
);

/**
 * Consumer to export
 */
export const Consumer = Context.Consumer;

/**
 * Consumer HOC to export
 */
export const withAuth = <P extends object>(
  Component: React.ComponentType<P & AuthContextInjectedProps>
) =>
  class WithAuth extends React.Component<P> {
    render() {
      return (
        <Context.Consumer>
          {(auth) => <Component {...this.props} auth={auth} />}
        </Context.Consumer>
      );
    }
  };

/**
 * Provider to export
 */
export class UnwrappedAuthProvider extends React.Component<
  AuthContextProps & FlashMessageContextInjectedProps,
  AuthContextState
> {
  private renewSessionFreeTimeout: number | undefined;

  constructor(props: AuthContextProps & FlashMessageContextInjectedProps) {
    super(props);
    this.state = {
      changePassword: this.changePassword,
      getAccessTokenFree: this.getAccessTokenFree,
      haltAuthentication: false,
      handleAuthenticationFree: this.handleAuthenticationFree,
      isAuthenticatedFree: this.isAuthenticatedFree,
      loginFree: this.loginFree,
      logout: this.logoutEverywhere,
      migratedUserPasswordReset: this.migratedUserPasswordReset,
      processingChangePassword: false,
      processingLogin: false,
      processingLogout: false,
      processingRegister: false,
      register: this.register,
      renewSessionFree: this.renewSessionFree,
      sessions: {
        "free:base": undefined,
      },
      setRedirectTo: this.setRedirectTo,
    };
  }

  handleAuthenticationFree = async (): Promise<void> => {
    if (this.state.processingLogin) {
      return;
    }

    if (this.isAuthenticatedFree()) {
      return navigateTo(this.getRedirectTo() || "/");
    }

    this.setState({ processingLogin: true });

    Auth0WrapperFree.handleAuthenticationFree()
      .then((authResult) => {
        this.setSession("free:base", authResult, () => {
          navigateTo(this.getRedirectTo() || "/");
        });
      })
      .catch((error) => {
        console.error("handleAuthenticationFree Error", error);

        this.props.flashMessage.setMessage({
          message: `Unable to log you in. ${
            error.errorDescription || error.error_description || ""
          }`,
          type: "error",
        });

        this.setState({ processingLogin: false, haltAuthentication: true });
        navigateTo("/");
      });
  };

  loginFree = (redirectToOverride?: string) => {
    let redirectTo = redirectToOverride;

    if (!redirectTo && window && window.location) {
      redirectTo = window.location.pathname;
    }

    GTMService.pushPageView("Login", "login.ii.co.uk", "Page");

    if (redirectTo) {
      this.setRedirectTo(redirectTo);
    }

    this.setState({
      haltAuthentication: false,
    });
    Auth0WrapperFree.loginFree();
  };

  setSession = (scope: AuthScope, authResult: any, cb: () => void) => {
    this.setState(
      {
        processingLogin: false,
        sessions: {
          ...this.state.sessions,
          [scope]: {
            accessToken: authResult.accessToken,
            expiresAt: JSON.stringify(
              authResult.expiresIn * 1000 + new Date().getTime()
            ),
            idToken: authResult.idToken,
          },
        },
      },
      () => cb()
    );
  };

  renewSessionFree = (): Promise<void> =>
    new Promise((resolve, reject) => {
      // If we are already processing a login, or we are halted for some reason, reject
      if (this.state.processingLogin || this.state.haltAuthentication) {
        return reject();
      }

      // If we have a local session, return it
      if (this.isAuthenticatedFree()) {
        return resolve();
      }

      this.setState({ processingLogin: true });

      // Attempt to renew session from SSO
      Auth0WrapperFree.handleSilentAuthenticationFree()
        .then((authResult) => {
          return this.setSession("free:base", authResult, () => {
            this.setState({ processingLogin: false });
            return resolve();
          });
        })
        .catch((error) => {
          if (
            typeof error.error !== "undefined" &&
            error.error !== "login_required"
          ) {
            console.error(error);

            this.props.flashMessage.setMessage({
              message: `Unable to log you in. ${
                error.errorDescription || error.error_description || ""
              }`,
              type: "error",
            });
          }

          this.setState({
            haltAuthentication: true,
          });

          // Logout just in case as we don't have an SSO session or there was a problem
          this.logoutLocal(false);
          return reject();
        });
    });

  scheduleSessionFreeRenewal = async () => {
    let nextCheckTime: number = 0;

    // We don't want to start the renewal if we are currently processing a logout
    if (this.state.processingLogout) {
      return;
    }

    // We don't want to start the renewal if we are on an auth route
    if (
      window.location.pathname.indexOf("/auth") === 0 ||
      window.location.pathname.indexOf("/logout") === 0 ||
      window.location.pathname.indexOf("/login") === 0
    ) {
      return;
    }

    const sessionState = this.state.sessions["free:base"];
    if (this.isAuthenticatedFree() && sessionState) {
      nextCheckTime = JSON.parse(sessionState.expiresAt) - Date.now() - 300000; // 300000 = 5 mins
    } else {
      await this.renewSessionFree().catch((status) => {
        return;
      });

      if (sessionState) {
        nextCheckTime =
          JSON.parse(sessionState.expiresAt) - Date.now() - 300000; // 300000 = 5 mins
      }
    }

    if (nextCheckTime > 0) {
      this.renewSessionFreeTimeout = window.setTimeout(() => {
        this.renewSessionFree().catch((status) => {
          if (this.renewSessionFreeTimeout) {
            window.clearTimeout(this.renewSessionFreeTimeout);
            this.renewSessionFreeTimeout = undefined;
          }
          return;
        });
      }, nextCheckTime);

      return;
    }
  };

  getAccessTokenFree = (): string | undefined => {
    if (this.checkSessionExpiresAt("free:base")) {
      return get(this.state, "sessions['free:base'].accessToken");
    }

    return undefined;
  };

  logoutLocal = (redirect: boolean = true) => {
    if (this.renewSessionFreeTimeout) {
      window.clearTimeout(this.renewSessionFreeTimeout);
      this.renewSessionFreeTimeout = undefined;
    }
    this.setState(
      {
        processingLogin: false,
        processingLogout: false,
        sessions: {
          "free:base": undefined,
        },
      },
      () => {
        if (redirect) {
          navigateTo("/");
        }
      }
    );
  };

  logoutEverywhere = () => {
    this.logoutLocal();

    // We don't need to change the `processingLogout` state back to false, as the below function
    // directs us away to Auth0 then back to the functions redirectTo (resetting the app state)
    Auth0WrapperFree.logoutFree();
  };

  getRedirectTo = () => {
    const redirectTo =
      (window &&
        window.sessionStorage &&
        window.sessionStorage.getItem("redirect_to")) ||
      undefined;
    this.removeRedirectTo();
    return redirectTo;
  };

  setRedirectTo = (redirectTo: string) => {
    if (window && window.sessionStorage) {
      window.sessionStorage.setItem("redirect_to", redirectTo);
    }
  };

  removeRedirectTo = () =>
    window &&
    window.sessionStorage &&
    window.sessionStorage.removeItem("redirect_to");

  isAuthenticatedFree = () => {
    return typeof this.getAccessTokenFree() !== "undefined";
  };

  checkSessionExpiresAt = (scope: AuthScope): boolean => {
    const sessionScope = this.state.sessions[scope];
    const expiresAtJson =
      this.state && sessionScope ? sessionScope.expiresAt : undefined;

    if (!expiresAtJson) {
      return false;
    }

    const expiresAt = JSON.parse(expiresAtJson);
    return new Date().getTime() < expiresAt;
  };

  changePassword = (email: string): Promise<void> =>
    new Promise((resolve, reject) => {
      this.setState({ processingChangePassword: true });

      Auth0WrapperFree.changePasswordFree(email)
        .then((result) => {
          this.props.flashMessage.setMessage({
            message: result as string,
            type: "success",
          });

          resolve();
        })
        .catch((error) => {
          console.error("changePassword Error", error);

          this.props.flashMessage.setMessage({
            message: `There was an error changing your password. ${error.message}`,
            type: "error",
          });

          this.setState({ processingChangePassword: false });

          reject();
        });
    });

  migratedUserPasswordReset = (email: string): Promise<void> =>
    new Promise((resolve, reject) => {
      this.setState({ processingChangePassword: true });

      Auth0WrapperFree.changePasswordFree(email)
        .then((result) => {
          this.props.flashMessage.setMessage({
            message:
              "If the email address you've entered is linked to your account, \
             you will get an email from us with instructions on how to reset your password.",
            // result as string
            type: "success",
          });

          resolve();
        })
        .catch((error) => {
          console.error("changePassword Error", error);

          this.props.flashMessage.setMessage({
            message: `There was an error changing your password. ${error.message}`,
            type: "error",
          });

          this.setState({ processingChangePassword: false });

          reject();
        });
    });

  register = (
    email: string,
    password: string,
    dailyAfternoonRoundup: boolean,
    weeklyMarketRoundup: boolean,
    emailMarketing: boolean,
    thirdpartyMarketing: boolean
  ): Promise<void> =>
    new Promise((resolve, reject) => {
      this.setState({ processingRegister: true });

      this.props.flashMessage.removeAllMessages();

      Auth0WrapperFree.registerFree(
        email,
        password,
        dailyAfternoonRoundup,
        weeklyMarketRoundup,
        emailMarketing,
        thirdpartyMarketing
      )
        .then((result) => {
          resolve();
        })
        .catch((error) => {
          console.error("register Error", error);

          let message: string =
            error.error === "user_exists"
              ? "This email address has already been taken"
              : "There was an error signing you up. Please try again later";

          this.props.flashMessage.setMessage({
            message: message,
            type: "error",
          });

          this.setState({ processingRegister: false });

          reject();
        });
    });

  componentDidMount() {
    this.scheduleSessionFreeRenewal();
  }

  componentWillUnmount() {
    if (this.renewSessionFreeTimeout) {
      window.clearTimeout(this.renewSessionFreeTimeout);
      this.renewSessionFreeTimeout = undefined;
    }
  }

  render() {
    return (
      <Context.Provider value={this.state}>
        {this.props.children}
      </Context.Provider>
    );
  }
}

export const Provider = withFlashMessage<AuthContextProps>(
  UnwrappedAuthProvider
);

const AuthContext = {
  Consumer,
  Provider,
};

export default AuthContext;
