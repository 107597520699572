// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-tsx": () => import("./../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-50-x-tsx": () => import("./../../src/pages/50x.tsx" /* webpackChunkName: "component---src-pages-50-x-tsx" */),
  "component---src-pages-auth-index-tsx": () => import("./../../src/pages/auth/index.tsx" /* webpackChunkName: "component---src-pages-auth-index-tsx" */),
  "component---src-pages-auth-login-tsx": () => import("./../../src/pages/auth/login.tsx" /* webpackChunkName: "component---src-pages-auth-login-tsx" */),
  "component---src-pages-auth-logout-tsx": () => import("./../../src/pages/auth/logout.tsx" /* webpackChunkName: "component---src-pages-auth-logout-tsx" */),
  "component---src-pages-auth-redirect-index-tsx": () => import("./../../src/pages/auth-redirect/index.tsx" /* webpackChunkName: "component---src-pages-auth-redirect-index-tsx" */),
  "component---src-pages-cookie-preferences-index-tsx": () => import("./../../src/pages/cookie-preferences/index.tsx" /* webpackChunkName: "component---src-pages-cookie-preferences-index-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-newsletter-preferences-index-tsx": () => import("./../../src/pages/newsletter-preferences/index.tsx" /* webpackChunkName: "component---src-pages-newsletter-preferences-index-tsx" */),
  "component---src-pages-register-index-tsx": () => import("./../../src/pages/register/index.tsx" /* webpackChunkName: "component---src-pages-register-index-tsx" */),
  "component---src-pages-settings-index-tsx": () => import("./../../src/pages/settings/index.tsx" /* webpackChunkName: "component---src-pages-settings-index-tsx" */),
  "component---src-pages-virtual-portfolio-index-tsx": () => import("./../../src/pages/virtual-portfolio/index.tsx" /* webpackChunkName: "component---src-pages-virtual-portfolio-index-tsx" */)
}

