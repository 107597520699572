import { CSSObject, Global } from "@emotion/core";
import elementClosest from "element-closest";
import objectFitImages from "object-fit-images";
import React from "react";
import smoothscroll from "smoothscroll-polyfill";
import "whatwg-fetch";

import {
  createGlobalStylesheetObject,
  FlashMessageContextProvider,
  LayoutContextProvider,
  ModalContextProvider,
  SiteSearchContextProvider,
} from "@interactive-investor/onestack-web-shared";

import AuthContext from "../src/contexts/Auth";

const globalStyleSheet = createGlobalStylesheetObject() as CSSObject;

export const onInitialClientRender = () => {
  // Polyfill for object fit on IE
  objectFitImages();
  // Polyfill for smooth scroll on safari
  smoothscroll.polyfill();
  elementClosest(window);
};

// eslint-disable-next-line react/prop-types
export const wrapRootElement = ({ element }: any) => {
  const isDevelopment = process.env.NODE_ENV === "development";
  return (
    <FlashMessageContextProvider>
      <AuthContext.Provider>
        <LayoutContextProvider>
          <SiteSearchContextProvider>
            <ModalContextProvider>
              {isDevelopment && <Global styles={globalStyleSheet} />}
              {element}
            </ModalContextProvider>
          </SiteSearchContextProvider>
        </LayoutContextProvider>
      </AuthContext.Provider>
    </FlashMessageContextProvider>
  );
};
