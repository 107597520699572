import * as auth0 from "auth0-js";
// import axios from "axios";

export interface AuthErrorType {
  code: number;
  error: string;
  message: string;
}

export class Auth0WrapperFree {
  public auth0Free = new auth0.WebAuth({
    domain: String(process.env.GATSBY_AUTH_DOMAIN),
    clientID: String(process.env.GATSBY_AUTH_CLIENT_ID),
    redirectUri: `${process.env.GATSBY_SITE_URL}/auth/cb/`, // this MUST have a trailing slash
    audience: `https://api.ii.co.uk/free`,
    responseType: "token id_token",
    scope: "openid email profile free:base",
  });

  public loginFree = () => this.auth0Free.authorize();

  public logoutFree = () =>
    this.auth0Free.logout({
      returnTo: `${process.env.GATSBY_MAIN_SITE_URL}/research-logout`,
    });

  public handleAuthenticationFree = () =>
    new Promise((resolve, reject) => {
      this.auth0Free.parseHash((err: any, authResult: any) => {
        if (authResult && authResult.accessToken && authResult.idToken) {
          resolve(authResult);
        } else if (err) {
          console.error(err);
          reject(err);
        } else {
          reject();
        }
      });
    });

  public handleSilentAuthenticationFree = () =>
    new Promise((resolve, reject) =>
      this.auth0Free.checkSession({}, (err: any, authResult: any) => {
        if (authResult && authResult.accessToken && authResult.idToken) {
          resolve(authResult);
        } else if (err) {
          reject(err);
        }
      })
    );

  public changePasswordFree = (
    email: string
  ): Promise<AuthErrorType | string> =>
    new Promise((resolve, reject) => {
      this.auth0Free.changePassword(
        {
          connection: "Username-Password-Authentication",
          email: email,
        },
        (err: any, res: any) => {
          if (err) {
            console.error(err);
            reject({
              code: 500,
              error: err.error,
              message: err.errorDescription,
            });
            return;
          }
          resolve(res);
        }
      );
    });

  public registerFree = (
    email: string,
    password: string,
    dailyAfternoonRoundup: boolean,
    weeklyMarketRoundup: boolean,
    emailMarketing: boolean,
    thirdpartyMarketing: boolean
  ): Promise<AuthErrorType | string> =>
    new Promise((resolve, reject) => {
      this.auth0Free.signup(
        {
          connection: "Username-Password-Authentication",
          email: email,
          password: password,
          userMetadata: {
            dailyAfternoonRoundup: JSON.stringify(dailyAfternoonRoundup),
            weeklyMarketRoundup: JSON.stringify(weeklyMarketRoundup),
            emailMarketing: JSON.stringify(emailMarketing),
            thirdpartyMarketing: JSON.stringify(thirdpartyMarketing),
          },
        },
        (err: any, res: any) => {
          if (err) {
            console.error(err);
            reject({
              code: 500,
              error: err.error,
              message: err.errorDescription,
            });
            return;
          }
          resolve(res);
        }
      );
    });
}

export default new Auth0WrapperFree();
